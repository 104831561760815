<section class="main-wrap">
  <div class="head-sec d-flex al-center js-between">
    <div class="left">
      <h2>New Leads</h2>
    </div>

    <div class="right">
      <button type="submit" form="newLeads" class="btn-outline-primary save-cta" [disabled]="!showSave" color="black">
        <i class="ri-save-2-line"></i>Save</button>
    </div>
  </div>

  <div class="inner-wrap">
    <form [formGroup]="newLeadForm" id="newLeads" (ngSubmit)="submitForm()">
    <div class="formbox">
      <div class="d-flex al-center flex-wrap">

        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Mobile Number</mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input id="mNumber" #mNumber formControlName="mobileNo" type="number" matInput placeholder="Enter Phone No" value="" (keypress)="restrictPhoneLength($event)"/>
            </mat-form-field>
          </div>
        </div>

        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Company PAN<em>*</em></mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input id="companyPan" #companyPan formControlName="companyPan" matInput placeholder="ABCDE1234A" value="" (keypress)="restrictPanInfo($event)"/>
              <button class="fetch-cta" (click)="fetchDetailsFromPan()" type="button" [disabled]="!showSave">Fetch Details</button>
            </mat-form-field>
          </div>
        </div>

        <div class="w-32 mb-15 gstinSearchMenuWrap">
          <div class="input-group">
            <mat-label>GSTIN No.(For Address)<em>*</em></mat-label>
            <mat-form-field  class="mat-chips"
            appearance="outline">
              <mat-chip-list #chipList formControlName="gstinNo">
                <mat-chip
                  *ngIf="gstSelected"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(gstSelected)">
                  {{gstSelected}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                  <input
                    placeholder="12ABCDE1234A1A1"
                    #fruitInput
                    [formControl]="gstCtrl"
                    [matAutocomplete]="auto"
                    (click)="getCall()"
                    (focusout)="setGstValue($event)"
                    (keyup.enter)="setGstValue($event)"
                    (keypress)="restrictGst($event)"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur">
                </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let gstSelected of filteredGst | async" value={{gstSelected}}>
                  <mat-radio-group class="mat-radio-column mt-10" aria-label="Select an option" (change)="selected($event)">
                    <mat-radio-button [value]="gstSelected">{{gstSelected}}</mat-radio-button>
                  </mat-radio-group>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            
          </div>
        </div>
        
        
        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Customer Name<em>*</em></mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input #supplierName id ="supplierName" formControlName="supplierName" matInput placeholder="Enter" value=""/>
            </mat-form-field>
          </div>
        </div>

        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Customer Turnover Last FY(In Crore)<em>*</em></mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input id="supplierTurnover" #supplierTurnover id="supplierTurnover" formControlName="supplierTurnover" type="number" (keypress)="resetrictOnlyNumbers($event)" matInput placeholder="Enter" value=""/>
            </mat-form-field>
          </div>
        </div>

        <!-- your-component.component.html -->
      <div class="w-32 mb-15">
        <div class="input-group">
            <mat-label>Type Of Company<em>*</em></mat-label>
            <mat-form-field class="mat-select" appearance="outline" >
            <mat-select id="typeOfCompany" #typeOfCompany formControlName="typeOfCompany" placeholder="Please Select">
              <mat-option class="mat-option" value="PRIVATE">Private</mat-option>
              <mat-option class="mat-option" value="PUBLIC">Public</mat-option>
              <mat-option class="mat-option" value="PROPRIETORSHIP">Proprietorship</mat-option>
              <mat-option class="mat-option" value="PARTNERSHIP">Partnership</mat-option>
              <mat-option class="mat-option" value="LLP">LLP</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
      </div>


        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Date of Incorporation<em>*</em></mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input matInput (focus)="MogDatePicker1.open()" [matDatepicker]="MogDatePicker1" placeholder="DD/MM/YYYY" formControlName="businessIncorporationYear" readonly/>
              <mat-datepicker-toggle matSuffix [for]="MogDatePicker1">
              </mat-datepicker-toggle>
              <mat-datepicker panelClass="atom-datepicker" #MogDatePicker1></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="w-32 mb-15">
          <div class="input-group">
              <mat-label>Business Product<em>*</em></mat-label>
              <mat-form-field class="mat-select" appearance="outline" >
              <mat-select id="businessProduct" #businessProduct formControlName="businessProduct" placeholder="Please Select">
                <mat-option class="mat-option" value="SID">SID</mat-option>
                <mat-option class="mat-option" value="PID">PID</mat-option>
                <mat-option class="mat-option" value="PO">PO</mat-option>
                <mat-option class="mat-option" value="VENDOR_FINANCE">VENDOR FINANCE</mat-option>
                <mat-option class="mat-option" value="DEALER_FINANCE">DEALER FINANCE</mat-option>
              </mat-select>
            </mat-form-field>
            </div>
        </div>

        <div class="w-32 mb-15">
          <div class="input-group">
              <mat-label>Nature of Business Industry<em>*</em></mat-label>
              <mat-form-field class="mat-select" appearance="outline" >
              <mat-select id="natureOfBusiness" #natureOfBusiness formControlName="natureOfBusiness" placeholder="Please Select">
                <mat-option class="mat-option" value="RETAILER">Retailer</mat-option>
                <mat-option class="mat-option" value="MANUFACTURER">Manufacturer</mat-option>
                <mat-option class="mat-option" value="WHOLESALER">Wholesaler</mat-option>
                <mat-option class="mat-option" value="PROFESSIONAL">Professional</mat-option>
                <mat-option class="mat-option" value="OTHER">Other</mat-option>
              </mat-select>
            </mat-form-field>
            </div>
        </div>

        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Total Funding Requirement(In Lakh)<em>*</em></mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input id="totalFundingRequirement" #totalFundingRequirement formControlName="totalFundingRequirement" type="number" (keypress)="resetrictOnlyNumbers($event)" matInput placeholder="Enter" value=""/>
            </mat-form-field>
          </div>
        </div>

        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Address Line 1<em>*</em></mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input id="addressLine1" #addressLine1 formControlName="addressLine1" matInput placeholder="Enter" value=""/>
            </mat-form-field>
          </div>
        </div>

        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Address Line 2</mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input formControlName="addressLine2" matInput placeholder="Enter" value=""/>
            </mat-form-field>
          </div>
        </div>

        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>City<em>*</em></mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input id="city" #city formControlName="city" matInput placeholder="Enter" value=""/>
            </mat-form-field>
          </div>
        </div>

        <div class="w-32 mb-15">
          <div class="input-group">
              <mat-label>State<em>*</em></mat-label>
              <mat-form-field class="mat-select" appearance="outline" >
              <mat-select id="state" #state formControlName="state" placeholder="Please Select">
                <mat-option class="mat-option"*ngFor="let item of states" value="{{item.city}}" selected>{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            </div>
        </div>

        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Pin Code<em>*</em></mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input id="pincode" #pincode formControlName="pincode" type="number" matInput placeholder="Enter" value="" (keypress)="restrictPincodeLength($event)"/>
            </mat-form-field>
          </div>
        </div>

        <!-- <div class="w-32 mb-15">
          <div class="input-group">
              <mat-label>Probability to convert</mat-label>
              <mat-form-field class="mat-select" appearance="outline" >
              <mat-select formControlName="probabilityToConvert" placeholder="Please Select">
                <mat-option class="mat-option" value="HIGH">High</mat-option>
                <mat-option class="mat-option" value="MEDIUM">Medium</mat-option>
                <mat-option class="mat-option" value="LOW">Low</mat-option>
              </mat-select>
            </mat-form-field>
            </div>
        </div> -->

        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Relationship Manager</mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input formControlName="relationshipManager" matInput placeholder="Enter" value="" (input)="restrictToLetters($event)" (blur)="removeLastSpaceFromRM($event)"/>
            </mat-form-field>
          </div>
        </div>

        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Lead Source<em>*</em></mat-label>
             <mat-form-field class="mat-select" appearance="outline" >
              <mat-select id="leadSource" #leadSource formControlName="leadSource" placeholder="Please Select">
                <mat-option class="mat-option" value="CHANNEL">Channel</mat-option>
                <mat-option class="mat-option" value="IS">IS</mat-option>
                <mat-option class="mat-option" value="DIRECT">Direct</mat-option>
                <mat-option class="mat-option" value="Marketing">Marketing</mat-option>
                <mat-option class="mat-option" value="REFERRAL">Referral</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
        </div>

        <div class="w-32 mb-15" *ngIf="leadSourceFlag">
          <div class="input-group">
            <mat-label>Lead Source Type</mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input formControlName="leadSouceType" matInput placeholder="Enter" value=""/>
            </mat-form-field>
          </div>
        </div>

        <!-- <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Go Live Month</mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input matInput (focus)="MogDatePicker.open()" [matDatepicker]="MogDatePicker" placeholder="DD/MM/YYYY" formControlName="goLiveMonth" readonly/>
              <mat-datepicker-toggle matSuffix [for]="MogDatePicker">
              </mat-datepicker-toggle>
              <mat-datepicker panelClass="atom-datepicker" #MogDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div> -->

        

        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>Sales Region</mat-label>
             <mat-form-field class="mat-input" appearance="outline" >
              <input formControlName="salesReason" matInput (input)="restrictToLetters($event)" (blur)="removelastSpaceFromSales($event)" placeholder="Enter" value=""/>
            </mat-form-field>
          </div>
        </div>

        <div class="w-32 mb-15">
          <div class="input-group">
            <mat-label>{{name}} Data</mat-label>

            <div class="upload-wrapper d-flex al-center js-between">
              <button  class="btn-outline-primary atom-input-file-btn" type="button" mat-stroked-button >
                <ng-container *ngIf="file == null">
                  <div class="atom-select-file" (click)="fileInput.value='';fileInput.click()" draggable="true" ngFileDragDrop (fileDropped)="fileUpload($event)">

                      <input style="display: none" type="file" (change)="fileUpload($event)" #fileInput [disabled]="!fieldEnable">
                      <span>
                         Upload <i class="ri-upload-2-line"></i>
                      </span>

                  </div>
                </ng-container>
                <ng-container *ngIf="file != null">
                     <div class="file-name-section">


                        <span class="file-name">
                            {{file.name}}

                        </span>
                        <a>
                          <i class="ri-close-line" (click)="rmeoveSelectedFile()"></i>
                        </a>
                      </div>
                </ng-container>
              </button>

              <button mat-button class="btn-basic" class="download-cta" type="button" (click)="downloadCsvFile()">
                <i class="ri-download-2-line"></i>Template</button>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="add-contact-wrap">
      <mat-accordion class="icon-accordion">
        <mat-expansion-panel [expanded]="panelOpenState">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Add Contact
            </mat-panel-title>

          </mat-expansion-panel-header>

          <div class="contact-section" formArrayName="contact">
               <div class="contact-row d-flex al-center" *ngFor="let control of formArr.controls;let i = index" [formGroupName]="i">
                <div class="w-22">
                  <div class="input-group">
                    <mat-label>Contact Name<em>*</em></mat-label>
                     <mat-form-field class="mat-input" appearance="outline" >
                      <input id="name" #name formControlName="name" matInput placeholder="Enter" (input)="restrictToLetters($event)" (blur)="removeLastSpaceFromContact($event,i)"/>
                    </mat-form-field>
                  </div>
                </div>


                <div class="w-22">
                  <div class="input-group">
                    <mat-label>Email<em>*</em></mat-label>
                     <mat-form-field class="mat-input" appearance="outline" >
                      <input id="email" #email formControlName="email" matInput placeholder="Enter" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" />
                    </mat-form-field>
                  </div>
                </div>

                <div class="w-22">
                  <div class="input-group">
                    <mat-label>Phone<em>*</em></mat-label>
                     <mat-form-field class="mat-input" appearance="outline" >
                      <input id="phone" #phone formControlName="phone" type="number" matInput placeholder="Enter" (keypress)="restrictPhoneLength($event)"/>
                    </mat-form-field>
                  </div>
                </div>


                <div class="w-22">
                  <div class="input-group">
                    <mat-label>Designation<em>*</em></mat-label>
                     <mat-form-field class="mat-input" appearance="outline" >
                      <input id="designation" #designation formControlName="designation" matInput placeholder="Enter" (input)="restrictToLetters($event)" (blur)="removeLastSpaceFromContactDesignation($event,i)"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="w-15">
                  <div class="input-group">
                    <mat-label class="primaryLabel">
                      <span *ngIf="i === 0">Set As Primary<em>*</em></span>
                    </mat-label>
                      <mat-checkbox formControlName="isPrimary" (change)="primarySet(i)" class="primaryCheck"></mat-checkbox>
                  </div>
                </div>

                <div class="actionbox">
                  <button class="del-cta" type="button" (click)="deleteRow(i)" *ngIf="formArr.controls.length > 1 && fieldEnable" ><i class="ri-delete-bin-line"></i> </button>
                  <button class="add-cta" type="button" (click)="addNewRow(i)" *ngIf="formArr.controls.length == i + 1 && fieldEnable" ><i class="ri-add-circle-line"></i> </button>
                </div>

               </div>
          </div>

        </mat-expansion-panel>

      </mat-accordion>

    </div>

    <div class="addSupplier-wrap" *ngIf="sidCheck">
      <mat-accordion class="icon-accordion">
        <mat-expansion-panel [expanded]="panelOpenState">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Add Buyer
            </mat-panel-title>

          </mat-expansion-panel-header>

          <div class="supplierTable">
            <div class="mat-table-overflow default-table-height">
              <table
                mat-table
                class="mat-table-theme horizontal-overflow-width mat-table-bordered"
                formArrayName="buyer"
                [dataSource]="dataSource1"
              >

                <ng-container matColumnDef="BuyerName" sticky>
                  <th mat-header-cell *matHeaderCellDef class="sticky-left">
                    <span class="pr-20">Buyer Legal Name<em>*</em> </span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i" class="sticky-left">
                    <mat-form-field class="mat-input" appearance="outline" >
                      <input id="buyerName" #buyerName matInput placeholder="Enter" formControlName="buyerName" disabled="false" (input)="restrictToLetterName($event)" (blur)="removeLastSpaceFromBuyer($event,i)"/>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="BuyerAddress">
                  <th mat-header-cell *matHeaderCellDef>
                    <span>Buyer Legal Address</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <div class="input-group">
                      <mat-form-field class="mat-input" appearance="outline" >
                       <input id="buyerAddress" #buyerAddress matInput placeholder="Enter" formControlName="buyerAddress" disabled="false"/>
                     </mat-form-field>
                   </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="BuyerRating">
                  <th mat-header-cell *matHeaderCellDef>
                    <span class="pr-20"> BuyerRating<em *ngIf="showMandatory">*</em> </span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <div class="input-group">
                      <mat-form-field class="mat-input" appearance="outline" >
                       <input id="buyerRating" #buyerRating matInput placeholder="Enter" formControlName="buyerRating" (input)="restrictToLetterName($event)" disabled="false"/>
                     </mat-form-field>
                   </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="RelationshipTurnover">
                  <th mat-header-cell *matHeaderCellDef>
                    <span> Relationship Turnover (in Crore)</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <div class="input-group">
                      <mat-form-field class="mat-input" appearance="outline" >
                       <input id="relationshipTurnover" #relationshipTurnover matInput placeholder="Enter" type="number" (keypress)="resetrictOnlyNumbers($event)" formControlName="relationshipTurnover"/>
                     </mat-form-field>
                   </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="BuyerTurnOver">
                  <th mat-header-cell *matHeaderCellDef>
                    <span> Buyer Turn Over</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <div class="input-group">
                      <mat-form-field class="mat-input" appearance="outline" >
                       <input id="buyerTurnover" #buyerTurnover matInput placeholder="Enter" type="number" (keypress)="resetrictOnlyNumbers($event)" formControlName="buyerTurnover"/>
                     </mat-form-field>
                   </div>
                  </td>
                </ng-container>


                <ng-container matColumnDef="LimitRequested">
                  <th mat-header-cell *matHeaderCellDef>
                    <span> Limit Requested</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <mat-form-field class="mat-input" appearance="outline" >
                      <input type="number" id="limitRequested" #limitRequested matInput placeholder="Enter" disabled="false" (keypress)="resetrictOnlyNumbers($event)" formControlName="limitRequested"/>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="TendorDays">
                  <th mat-header-cell *matHeaderCellDef>
                    <span> Tenor(Days) Requested</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <mat-form-field class="mat-input" appearance="outline" >
                      <input id="tenorDaysRequested" type="number" #tenorDaysRequested matInput placeholder="Enter" onpaste="return false;" formControlName="tenorDaysRequested" (keypress)="restrictTenorDays($event)"/>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="valueOfPo">
                  <th mat-header-cell *matHeaderCellDef>
                    <span> Value Of PO For Next Six Months</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <div class="input-group">
                      <mat-form-field class="mat-input" appearance="outline" >
                       <input id="valueOfPo" #valueOfPo matInput type="number" formControlName="valueOfPo" (keypress)="resetrictOnlyNumbers($event)" placeholder="Enter"/>
                     </mat-form-field>
                   </div>
                  </td>
                </ng-container>


                <ng-container matColumnDef="Action" stickyEnd>
                  <th mat-header-cell *matHeaderCellDef class="sticky-right min-w-60">
                    <span class="pr-20">Action </span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i" class="sticky-right action-cta-col">
                    <button class="add-cta table-cta" type="button" (click)="addNewRowBuyer(i)" *ngIf="formArrBuyer.controls.length == i + 1 && fieldEnable">Add </button>
                    <button class="del-cta table-cta" type="button" (click)="deleteRowBuyer(i)" *ngIf="formArrBuyer.controls.length > 1 && fieldEnable">Delete </button>
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayColumns1; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayColumns1"
                ></tr>
              </table>
            </div>

          </div>

        </mat-expansion-panel>

      </mat-accordion>

    </div>

    <div class="addSupplier-wrap" *ngIf="piCheck">
      <mat-accordion class="icon-accordion">
        <mat-expansion-panel [expanded]="panelOpenState">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Add Supplier
            </mat-panel-title>

          </mat-expansion-panel-header>

          <div class="supplierTable">
            <div class="mat-table-overflow default-table-height">
              <table
                mat-table
                class="mat-table-theme horizontal-overflow-width mat-table-bordered"
                formArrayName="supplier"
                [dataSource]="dataSource2"
              >

                <ng-container matColumnDef="SupplierName" sticky>
                  <th mat-header-cell *matHeaderCellDef class="sticky-left">
                    <span class="pr-20">Supplier Legal Name<em>*</em> </span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i" class="sticky-left">
                    <mat-form-field class="mat-input" appearance="outline" >
                      <input matInput placeholder="Enter" formControlName="supplierName" disabled="false" (input)="restrictToLetterName($event)" (blur)="removeLastSpaceFromSupplier($event,i)"/>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="SupplierAddress">
                  <th mat-header-cell *matHeaderCellDef>
                    <span>Supplier Legal Address</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <div class="input-group">
                      <mat-form-field class="mat-input" appearance="outline" >
                       <input matInput placeholder="Enter" formControlName="supplierAddress" disabled="false"/>
                     </mat-form-field>
                   </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="SupplierRating">
                  <th mat-header-cell *matHeaderCellDef>
                    <span class="pr-20"> Supplier Rating</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <div class="input-group">
                      <mat-form-field class="mat-input" appearance="outline" >
                       <input id="supplierRating" #supplierRating matInput placeholder="Enter" formControlName="supplierRating" (input)="restrictToLetterName($event)" disabled="false"/>
                     </mat-form-field>
                   </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="SupplierRelationshipTurnover">
                  <th mat-header-cell *matHeaderCellDef>
                    <span> Relationship Turnover (in Crore)</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <div class="input-group">
                      <mat-form-field class="mat-input" appearance="outline" >
                       <input id="supplierRelationshipTurnover" #supplierRelationshipTurnover matInput placeholder="Enter" (keypress)="resetrictOnlyNumbers($event)" type="number" formControlName="relationshipTurnover"/>
                     </mat-form-field>
                   </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="SupplierTurnOver">
                  <th mat-header-cell *matHeaderCellDef>
                    <span> Supplier Turn Over</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <div class="input-group">
                      <mat-form-field class="mat-input" appearance="outline" >
                       <input id="supplierTurnover" #supplierTurnover matInput placeholder="Enter" type="number" (keypress)="resetrictOnlyNumbers($event)" formControlName="supplierTurnover"/>
                     </mat-form-field>
                   </div>
                  </td>
                </ng-container>


                <ng-container matColumnDef="LimitRequested">
                  <th mat-header-cell *matHeaderCellDef>
                    <span> Limit Requested</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <mat-form-field class="mat-input" appearance="outline" >
                      <input type="number" matInput id="limitRequested" #limitRequested type="number" placeholder="Enter" (keypress)="resetrictOnlyNumbers($event)" disabled="false" formControlName="limitRequested"/>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="TendorDays">
                  <th mat-header-cell *matHeaderCellDef>
                    <span> Tenor(Days) Requested</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <mat-form-field class="mat-input" appearance="outline" >
                      <input matInput placeholder="Enter Tenor" formControlName="tenorDaysRequested"  onpaste="return false;" (keypress)="restrictTenorDays($event)"/>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="valueOfPo">
                  <th mat-header-cell *matHeaderCellDef>
                    <span> Value Of PO For Next Six Months</span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i">
                    <div class="input-group">
                      <mat-form-field class="mat-input" appearance="outline" >
                       <input matInput type="number" formControlName="valueOfPo" placeholder="Enter" (keypress)="resetrictOnlyNumbers($event)"/>
                     </mat-form-field>
                   </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Action" stickyEnd>
                  <th mat-header-cell *matHeaderCellDef class="sticky-right min-w-60">
                    <span class="pr-20">Action </span>
                  </th>
                  <td mat-cell *matCellDef="let element;let i = index" [formGroupName]="i" class="sticky-right action-cta-col">
                    <button class="add-cta table-cta" type="button" (click)="addNewRowSupplier(i)" *ngIf="formArrSupplier.controls.length == i + 1 && fieldEnable">Add </button>
                    <button class="del-cta table-cta" type="button" (click)="deleteRowSupplier(i)" *ngIf="formArrSupplier.controls.length > 1 && fieldEnable">Delete </button>
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayColumns2; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayColumns2"
                ></tr>
              </table>
            </div>

          </div>

        </mat-expansion-panel>

      </mat-accordion>

    </div>

  </form>
  </div>
</section>
