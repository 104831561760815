import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class InactivityService {
    private logoutTimer: any;
    private readonly timeout: number = 1 * 60 * 1000; // 30 minutes

    constructor(private router: Router, private ngZone: NgZone, public authService: AuthService) {
        this.initListener();
        this.resetTimer(); // Start timer initially
    }

    initListener() {
        // Listen for user activity and reset the timer on each activity
        window.addEventListener('mousemove', () => this.resetTimer());
        window.addEventListener('keydown', () => this.resetTimer());
        window.addEventListener('click', () => this.resetTimer());
    }

    resetTimer() {
        // Clear any existing timer and restart the logout timer
        clearTimeout(this.logoutTimer);
        this.logoutTimer = setTimeout(() => {
            this.ngZone.run(() => {
                this.logout();
            });
        }, this.timeout);
    }

    logout() {
        sessionStorage.clear();
        this.authService.destroySession();
        this.router.navigate(['/auth/login'])
            .then(() => {
                window.localStorage.setItem('logged_in', 'false');
                window.location.reload();
            });
    }
}