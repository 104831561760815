<div id="curtain" class="curtain">
  <div id="curtain-spinner" class="curtain-spinner"></div>
</div>
<mat-toolbar id="mainToolbar" color="primary" *ngIf="loginDetails && pageNotFoundPage">
  <mat-icon (click)="onToolbarMenuToggle()" class="menuicon" id="toolbarMenu">subject</mat-icon>
  <a style="height: 34%;width: 5%;" (click)="navigateToSupplier()"><img
      src="/assets/images/loginlogo.svg" style="width: 100%;height: 25px;"></a>

  <div class="autoHederSearch" *ngIf="isCustomer || isCredlixUser || authService.isSIDUSER() ">
    <app-global-filters *ngIf="!isGlobalFilterShown && !authService.isAnchorFinancing()"></app-global-filters>
  </div>
  <span class="toolbar-spacer"></span>
  <div class="rightheadarea">

    <div *ngIf="isCredlixUser && !authService.isAnchorFinancing()" style="margin-right: 20px;">
      <mat-form-field class="buyerList" *ngIf="!isGlobalFilterShown">
        <mat-select style="width: 80px;" [(value)]="anchorType" (selectionChange)="changeAnchor($event.value)">
          <mat-option style="font-size: 10px;" *ngFor="let anchor of anchorList" [value]="anchor">
            {{anchor.displayName}}
          </mat-option>
        </mat-select>
        <i matSuffix class="ri-arrow-down-s-fill"></i>
      </mat-form-field>
    </div>
  
    <div class="notification" *ngIf="this.authService.isSIDUSER()">
      <button mat-button [matMenuTriggerFor]="notification" [class.ring]="unreadAlerts > 0"
        [disabled]="!notificationList?.length">
        <span class="bell-icon">
          <mat-icon [matBadge]="unreadAlerts">notifications_none</mat-icon>
        </span>
      </button>
      <mat-menu #notification="matMenu" class="sortingmenu">
        <div *ngFor="let item of notificationList; let i = index">
          <div mat-menu-item (click)="dismissNotification(item,i)">
            <div class="content-wrapper">
              <i class="ri-check-line"></i>{{item.message}}
              <span *ngIf="!item.seen" class="status-dot"></span>
            </div>
          </div>
        </div>
      </mat-menu>

    </div>

    <div fxFlex="2" class="user_prf" [matMenuTriggerFor]="accSwitch" (click)="getUserRoles()"><span
        matTooltip="My Account">{{userProfile}}</span>
    </div>
    <mat-menu class="accountSwitch" #accSwitch="matMenu">
      <!-- <AccountAuthorizationComponent></AccountAuthorizationComponent> -->
      <mat-card fxLayout="row" style="padding: 0;" class="slct">
        <div class="scrollable">
          <!-- <div class="frst"> -->
          <div class="company-admin-blue">
            <span class="icns"
              *ngIf="selectedRole.roleType=='CREDLIX' && !selectedRole.masterAccount?.isAnchorFinancing"><img
                src="/assets/icons/blue-man.svg" /></span>
            <span class="icns" *ngIf="selectedRole.masterAccount?.isAnchorFinancing"><img
                src="/assets/icons/export-finance.svg" /></span>
            <span class="icns"
              *ngIf="(selectedRole.roleType!='CREDLIX' && !selectedRole.masterAccount?.isExim) && !selectedRole.masterAccount?.isAnchorFinancing"><img
                src="/assets/icons/orangehand.svg" /></span>
            <span class="icns"
              *ngIf="selectedRole.roleType!='CREDLIX' && selectedRole.masterAccount?.isExim && !selectedRole.masterAccount?.isAnchorFinancing"><img
                src="/assets/icons/greenhand.svg" /></span>
            <div class="w-100">
              <span class="qwerty">
                <label
                  *ngIf="selectedRole.businessName!=null && selectedRole.roleType!='BORROWER' && !selectedRole.masterAccount?.isAnchorFinancing">
                  {{selectedRole.businessName | titlecase}} ({{selectedRole.roleName ? (selectedRole.roleName |
                  removeUnderscore | titlecase) : '--'}})</label>
                <label *ngIf="selectedRole.businessName!=null && selectedRole.roleType=='BORROWER'">
                  {{selectedRole.businessName | titlecase}} (Supplier)</label>
              </span>
              <span
                *ngIf="selectedRole.businessName == null && selectedRole.roleType!='BORROWER' && !selectedRole.masterAccount?.isAnchorFinancing"
                class="qwerty">
                {{selectedRole.roleType | titlecase}} ({{selectedRole.roleName ? (selectedRole.roleName |
                removeUnderscore | titlecase) : '--'}})</span>
              <span *ngIf="selectedRole.businessName == null && selectedRole.masterAccount?.isExim "
                class="qwerty">Nuphi
                ({{selectedRole.roleName ? (selectedRole.roleName | removeUnderscore | titlecase) : '--'}})</span>
              <span *ngIf="selectedRole.masterAccount?.isAnchorFinancing" class="qwerty">Anchor Financing
                ({{selectedRole.roleName ? (selectedRole.roleName | removeUnderscore | titlecase) : '--'}})</span>
              <div class="d-flex  w-100 wrap">
                <!-- <span class="rety" *ngIf="items.gstNumber == null">{{items.userName}}</span> -->
                <span class="rety" *ngIf="selectedRole.gstNumber != null">{{selectedRole.gstNumber}}</span>
                <span class="rety" *ngIf="selectedRole.state != null">({{selectedRole.state}})</span>
                <span class="rety" *ngIf="selectedRole.masterAccount?.isDomestic">Domestic</span>
                <span class="rety" *ngIf="selectedRole.masterAccount?.isExim">Export</span>
                <span class="rety" *ngIf="selectedRole.roleType =='BORROWER'">Supplier</span>
                <span class="rety" *ngIf="selectedRole.roleType =='CREDLIX'">Credlix User</span>
                <span class="rety"
                  *ngIf="selectedRole.roleType !='BORROWER' && selectedRole.roleType!='CREDLIX' && !selectedRole.masterAccount?.isAnchorFinancing">
                  {{selectedRole.roleType | titlecase}}</span>
                <span class="rety" *ngIf="selectedRole.masterAccount?.isAnchorFinancing">Anchor</span>
              </div>
            </div>
          </div>
          <mat-card-content>
            <div class="margin-top">
              <div class="toggle-div">
                <label>Login to this account next time</label>
                <mat-slide-toggle (click)="$event.stopPropagation()" (change)="onStatusChange($event)"
                  [checked]="isDefaultSelected">
                </mat-slide-toggle>
              </div>
              <div *ngFor="let items of userDetail" (click)="setAccSwith(items)" class="accnt" style="cursor: pointer;"
                [ngClass]="{'acc-active': items.default == true}">
                <div class="company-admin">
                  <span class="icns blue-man"
                    *ngIf="items.roleType=='CREDLIX' && !items.masterAccount?.isAnchorFinancing"><img
                      src="/assets/icons/blue-man.svg" /></span>
                  <span class="icns" *ngIf="items.masterAccount?.isAnchorFinancing"><img
                      src="/assets/icons/export-finance.svg" /></span>
                  <span class="icns"
                    *ngIf="(items.roleType!='CREDLIX' && !items.masterAccount.isExim && !items.masterAccount?.isAnchorFinancing)"><img
                      src="/assets/icons/orangehand.svg" /></span>
                  <span class="icns"
                    *ngIf="items.roleType!='CREDLIX' && items.masterAccount.isExim && !items.masterAccount?.isAnchorFinancing"><img
                      src="/assets/icons/bluehand.svg" /></span>
                  <div class="w-100">
                    <span class="qwerty">
                      <label
                        *ngIf="items.businessName!=null && items.roleType!='BORROWER' && !items.masterAccount?.isAnchorFinancing">{{items.businessName
                        |
                        titlecase}}
                        ({{items.roleName ? (items.roleName | titlecase) : '--'}})</label>
                      <label
                        *ngIf="items.businessName!=null && items.roleType =='BORROWER' && !items.masterAccount?.isAnchorFinancing">{{items.businessName
                        |
                        titlecase}}
                        (Supplier)</label>
                    </span>
                    <span
                      *ngIf="items.businessName == null && items.roleType!='BORROWER' && !items.masterAccount?.isAnchorFinancing"
                      class="qwerty">{{items.roleType |
                      titlecase}}
                      ({{items.roleName ? (items.roleName | removeUnderscore | titlecase) : '--'}})</span>
                    <span *ngIf="items.businessName == null && items.masterAccount.isExim " class="qwerty">Nuphi
                      ({{items.roleName ? (items.roleName | removeUnderscore | titlecase) : '--'}})</span>
                    <span *ngIf="items.masterAccount?.isAnchorFinancing" class="qwerty">Anchor Financing
                      ({{items.roleName ? (items.roleName | removeUnderscore | titlecase) : '--'}})</span>

                    <div class="d-flex  w-100 wrap">
                      <!-- <span class="rety" *ngIf="items.gstNumber == null">{{items.userName}}</span> -->
                      <span class="rety" *ngIf="items.gstNumber != null">{{items.gstNumber}}</span>
                      <span class="rety" *ngIf="items.state != null">({{items.state}})</span>
                      <span class="rety" *ngIf="items.masterAccount?.isDomestic">Domestic</span>
                      <span class="rety" *ngIf="items.masterAccount.isExim">Export</span>
                      <span class="rety" *ngIf="items.roleType =='BORROWER'">Supplier</span>
                      <span class="rety" *ngIf="items.roleType =='CREDLIX'">Credlix
                        User</span>
                      <span class="rety"
                        *ngIf="items.roleType !='BORROWER' && items.roleType!='CREDLIX' && !items.masterAccount?.isAnchorFinancing">{{items.roleType
                        |
                        titlecase}}</span>
                      <span class="rety" *ngIf="items.masterAccount?.isAnchorFinancing">Anchor</span>
                      <!-- <span class="rety" *ngIf="items.masterAccount.displayName">({{items.masterAccount.displayName}})</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="login-sign-btn">
              <button (click)="navigateTOChangePassword()">Change Password</button>
              <button (click)="logout()">Sign out</button>
            </div>
          </mat-card-content>
        </div>
        <!-- </div> -->
      </mat-card>
    </mat-menu>
    <!-- <mat-icon [matMenuTriggerFor]="menu" class="moreicon">more_vert</mat-icon>
    <mat-menu #menu="matMenu" class="menuListProfile">
      <button mat-menu-item>
        <a (click)="navigateTOChangePassword()">Change Password</a>
      </button>
      <button mat-menu-item (click)="logout()">
        <a target="_blank">Logout</a>
      </button>
      <hr>
      <span class="swth" *ngIf="businessName==null">{{displayName | titlecase}}</span>
      <span class="swth" *ngIf="businessName!==null">{{businessName | titlecase}}</span>
      <div class="myth" *ngIf="gstNumber!==null">
        <br>
        <span>GST:-{{gstNumber}}</span>
      </div>
      <div>
        <span class="myth" *ngIf="roleType =='BORROWER'">Supplier ({{roleName | removeUnderscore | titlecase}})</span>
        <span class="myth" *ngIf="roleType!='BORROWER'">{{roleType|titlecase}} ({{roleName | removeUnderscore |
          titlecase}})</span>
      </div>
      <button mat-menu-item (click)="switchAccount()">
        <a target="_blank">Switch Account</a>
      </button>
    </mat-menu> -->
  </div>
</mat-toolbar>
<mat-sidenav-container id="sidenavContainer" fullscreen
  *ngIf="loginDetails && pageNotFoundPage && !pagesService.isSubmitted" [ngClass]="{'full': sidenavfull == true}">
  <mat-sidenav mode="side" id="sidenav">
    <mat-nav-list class="dash-sidenav-list">
      <mat-icon>subject</mat-icon>
    </mat-nav-list>
    <div class="sidenav_items">
      <mat-nav-list class="dash-sidenav-list">

        <!---------------------------Start of Anchor Financing----------------------------------->
        <ng-container *ngIf="authService.isAnchorFinancing() ; else otherServices">
          <ng-container *ngIf="userInfoData['afAnchor'] != 'WAAREE' && authService.isCredlixAnchor()">

            <div>
              <mat-list-item routerLink="/anchor/dashboard" routerLinkActive="active-list-item"
                (click)="resetGlobaFiler('white_das_anchor');">
                <img src="/assets/icons/grey_dashboard.svg" id="grey_das_anchor">
                <img src="/assets/icons/white_dashboard.svg" id="white_das_anchor">
                <a matLine>Dashboard</a>
              </mat-list-item>

              <mat-list-item routerLink="/anchor/invoice" routerLinkActive="active-list-item"
                (click)="resetGlobaFiler('anchor_invoice_white',true);">
                <img src="/assets/icons/sidInvoice.svg" id="anchor_invoice_grey">
                <img src="/assets/icons/sidInvoice-bg.svg" id="anchor_invoice_white">
                <a matLine>Invoices </a>
              </mat-list-item>

              <mat-list-item routerLink="/anchor/listing"
                routerLinkActive="active-list-item" (click)="resetGlobaFiler('anchor_white',true);">
                <img src="/assets/icons/buyers-icon.svg" id="anchor_grey">
                <img src="/assets/icons/buyers-icon-bg.svg" id="anchor_white">
                <a matLine>Anchors</a>
              </mat-list-item>

              <!-- <mat-list-item routerLink="/anchor/supplier" routerLinkActive="active-list-item"
                (click)="resetGlobaFiler('anchor_supplier_white',true);">
                <img src="/assets/icons/exporters-icon.svg" id="anchor_supplier_grey">
                <img src="/assets/icons/exporters-icon-bg.svg" id="anchor_supplier_white">
                <a matLine>Suppliers </a>
              </mat-list-item> -->
            </div>
          </ng-container>

          <!-- WARREE SUPPLIER SIDEBAR LIST-->

          <ng-container *ngIf="userInfoData['afAnchor'] == 'WAAREE' && authService.isAnchorFinanceBorrower()">
            <!-- <mat-list-item routerLink="/vendor-supplier/dashboard" routerLinkActive="active-list-item"
              (click)="resetGlobaFiler('white_das_anchor');">
              <img src="/assets/icons/grey_dashboard.svg" id="grey_das_anchor">
              <img src="/assets/icons/white_dashboard.svg" id="white_das_anchor">
              <a matLine>Dashboard</a>
            </mat-list-item> -->

            <mat-list-item routerLink="/vendor-supplier/invoice" routerLinkActive="active-list-item"
              (click)="resetGlobaFiler('anchor_invoice_white',true);">
              <img src="/assets/icons/sidInvoice.svg" id="anchor_invoice_grey">
              <img src="/assets/icons/sidInvoice-bg.svg" id="anchor_invoice_white">
              <a matLine>Invoices </a>
            </mat-list-item>
          </ng-container>


          <!--(Lender | Anchor | Sales) Side Bar Tab -->
          <ng-container *ngIf="userInfoData['afAnchor'] == 'WAAREE' && (authService.isAnchorFinanceLender() || authService.isAnchorFinanceAnchor() ||  authService.isAnchorFinanceSales())">
            <!-- <mat-list-item routerLink="/vendor/dashboard" routerLinkActive="active-list-item"
              (click)="resetGlobaFiler('white_das_anchor');">
              <img src="/assets/icons/grey_dashboard.svg" id="grey_das_anchor">
              <img src="/assets/icons/white_dashboard.svg" id="white_das_anchor">
              <a matLine>Dashboard</a>
            </mat-list-item> -->

            <mat-list-item routerLink="/vendor/invoice" routerLinkActive="active-list-item"
              (click)="resetGlobaFiler('anchor_invoice_white',true);">
              <img src="/assets/icons/sidInvoice.svg" id="anchor_invoice_grey">
              <img src="/assets/icons/sidInvoice-bg.svg" id="anchor_invoice_white">
              <a matLine>Invoices</a>
            </mat-list-item>

            <mat-list-item routerLink="/vendor/supplier" routerLinkActive="active-list-item"
              (click)="resetGlobaFiler('anchor_supplier_white',true);">
              <img src="/assets/icons/exporters-icon.svg" id="anchor_supplier_grey">
              <img src="/assets/icons/exporters-icon-bg.svg" id="anchor_supplier_white">
              <a matLine>Suppliers </a>
            </mat-list-item>
          </ng-container>

        </ng-container>

        <ng-container>

        </ng-container>
        <!---------------------------End of Anchor Financing----------------------------------->
        <ng-template #otherServices>

          <div *ngIf="isCredlixUser && !authService.isAnchorFinancing()">
            <mat-list-item class="doc" routerLink="/dashboard" routerLinkActive="active-list-item"
              (click)="resetGlobaFiler('white_das');" *hasPermission="['VIEW_DASHBOARD']">
              <img src="/assets/icons/grey_dashboard.svg" id="grey_das">
              <img src="/assets/icons/white_dashboard.svg" id="white_das">
              <!-- <i class="ri-bar-chart-box-line icon-color"></i> -->
              <a matLine>Dashboard</a>
            </mat-list-item>
          </div>

          <div *ngIf="(isCustomer || isCredlixUser) && epfinancing && !authService.isLenderLeads()
        && !isSidUser">
            <mat-list-item class="e_pay" routerLink="/supplierLinks" routerLinkActive="active-list-item"
              *hasPermission="['VIEW_BORROWER']" (click)="resetGlobaFiler('EP_white');">
              <img src="/assets/icons/EP_grey.svg" id="EP_grey">
              <img src="/assets/icons/EP_white.svg" id="EP_white">
              <!-- <i class="ri-speed-line icon-color"></i> -->
              <a matLine>Early Payments </a>
            </mat-list-item>
          </div>

          <div *ngIf="isCredlixUser && !channleFinancing">
            <mat-list-item class="doc" routerLink="/commission-invoices" routerLinkActive="active-list-item"
              *hasPermission="['VIEW_SYSTEM_INVOICES']" (click)="resetGlobaFiler('white_doc_ci');">
              <img src="/assets/icons/CI_blue.svg" id="grey_doc_ci">
              <img src="/assets/icons/CI_white.svg" id="white_doc_ci">
              <!-- <i class="ri-mail-download-line icon-color"></i> -->
              <a matLine>Comm. Invoices</a>
            </mat-list-item>
          </div>
          <div *ngIf="(isCustomer || isCredlixUser)  && epfinancing && !authService.isLenderLeads()
      && !isSidUser && !authService.isJKFennerAnchor()">
            <mat-list-item class="e_pay" routerLink="/suppliers/discount-debit-notes"
              routerLinkActive="active-list-item" *hasPermission="['VIEW_BORROWER']"
              (click)="resetGlobaFiler('EP_white', true);">
              <mat-icon id="EP_grey" class="material-symbols-outlined"
                style="margin: 8px; font-size: 26px;">text_snippet</mat-icon>
              <mat-icon id="EP_white" class="material-symbols-outlined"
                style="margin: 8px; font-size: 26px;">text_snippet</mat-icon>
              <a matLine>Discount Debit Notes </a>
            </mat-list-item>
          </div>
          <div *ngIf="!isCustomer && epfinancing && !isCredlixUser && !authService.isLenderLeads() &&
         !channleFinancing && !isSidUser">
            <mat-list-item class="e_pay" routerLink="/earlyPayments" routerLinkActive="active-list-item"
              *hasPermission="['VIEW_INVOICES']" (click)="resetGlobaFiler('EP_white');">
              <img src="/assets/icons/EP_grey.svg" id="EP_grey">
              <img src="/assets/icons/EP_white.svg" id="EP_white">
              <!-- <i class="ri-speed-line icon-color"></i> -->
              <a matLine>Early Payments </a>
            </mat-list-item>
          </div>

          <div *ngIf="!isCustomer && epfinancing && !isCredlixUser && !authService.isLenderLeads() &&
      !channleFinancing && !isSidUser && !authService.isJKFennerAnchor()">
            <mat-list-item class="e_pay" routerLink="/suppliers/discount-debit-notes"
              routerLinkActive="active-list-item" *hasPermission="['VIEW_INVOICES']"
              (click)="resetGlobaFiler('EP_white', true);">
              <mat-icon id="EP_grey" class="material-symbols-outlined"
                style="margin: 8px; font-size: 26px;">text_snippet</mat-icon>
              <mat-icon id="EP_white" class="material-symbols-outlined"
                style="margin: 8px; font-size: 26px;">text_snippet</mat-icon>
              <a matLine>Discount Debit Notes </a> <!--For Suup;liers -->
            </mat-list-item>
          </div>
          <mat-list-item class="e_pay" routerLink="/channelFinance" routerLinkActive="active-list-item"
            *ngIf="((isCustomer || isCredlixUser) && channleFinancing) || authService.isLenderLeads() && !authService.isBuyer()"
            (click)="resetGlobaFiler('finance_white');">
            <img src="/assets/icons/finance_grey.svg" id="finance_grey">
            <img src="/assets/icons/finance_white.svg" id="finance_white">
            <!-- <i class="ri-exchange-fill icon-color"></i> -->
            <a matLine>Channel Financing </a>
          </mat-list-item>
          <mat-list-item class="e_pay" routerLink="/financeListing" routerLinkActive="active-list-item"
            *ngIf="channleFinancing && authService.isBuyer() && !authService.isLenderLeads()"
            (click)="resetGlobaFiler('finance_white');">
            <img src="/assets/icons/finance_grey.svg" id="finance_grey">
            <img src="/assets/icons/finance_white.svg" id="finance_white">
            <!-- <i class="ri-exchange-fill icon-color"></i> -->
            <a matLine>Channel Financing </a>
          </mat-list-item>
          <mat-list-item class="e_pay" routerLink="/bankLists" routerLinkActive="active-list-item" *ngIf="!isCustomer && channleFinancing && !isCredlixUser && !authService.isLenderLeads()
          &&  !authService.isBuyer()" (click)="resetGlobaFiler('finance_white');">
            <img src="/assets/icons/finance_grey.svg" id="finance_grey">
            <img src="/assets/icons/finance_white.svg" id="finance_white">
            <!-- <i class="ri-exchange-fill icon-color"></i> -->
            <a matLine>Channel Financing </a>
          </mat-list-item>

          <div *ngIf="(authService.isLenderLeads() || authService.isCredlixUser()) && !authService.isSIDLenderLogin()">
            <mat-list-item class="repay" routerLink="/leads" routerLinkActive="active-list-item"
              *hasPermission="['VIEW_BORROWER']" (click)="resetGlobaFiler('leads_white');">
              <img src="/assets/icons/leads_white.svg" id="leads_white">
              <img src="/assets/icons/leads_blue.svg" id="leads_grey">
              <!-- <i class="ri-at-line icon-color"></i> -->
              <a matLine>Leads </a>
            </mat-list-item>
          </div>

          <div *ngIf="authService.isSIDUSER()">
            <mat-list-item class="repay" routerLink="/sid/listing/buyer" routerLinkActive="active-list-item"
              *hasPermission="['VIEW_BUYERS']" (click)="resetGlobaFiler('buyer_white');">
              <img src="/assets/icons/buyers-icon.svg" id="buyer_grey">
              <img src="/assets/icons/buyers-icon-bg.svg" id="buyer_white">
              <!-- <i class="ri-shopping-cart-fill icon-color"></i> -->
              <a matLine>Buyers </a>
            </mat-list-item>
          </div>
          <div *ngIf="authService.isSIDUSER() && authService.isSIDRoleType()">
            <mat-list-item class="repay" routerLink="/sid/listing/supplier" routerLinkActive="active-list-item"
              (click)="resetGlobaFiler('supplier_white');" *hasPermission="['VIEW_BORROWER']">
              <img src="/assets/icons/exporters-icon.svg" id="supplier_grey">
              <img src="/assets/icons/exporters-icon-bg.svg" id="supplier_white">
              <!-- <i class="ri-ship-fill icon-color"></i> -->
              <a matLine>Suppliers </a>
            </mat-list-item>
          </div>

          <div *ngIf="authService.isSIDUSER() && authService.isSIDRoleType() && !authService.isBorrower()">
            <mat-list-item class="repay" routerLink="/sid/statusview/invoices" routerLinkActive="active-list-item"
              (click)="resetGlobaFiler('invoice-white');" *hasPermission="['VIEW_DASHBOARD','VIEW_BUYERS']">
              <img src="/assets/icons/sidInvoice.svg" id="invoice-grey">
              <img src="/assets/icons/sidInvoice-bg.svg" id="invoice-white">
              <a matLine>Invoices </a>
            </mat-list-item>
          </div>

          <mat-list-item class="repay" routerLink="/sid/invoiceListing" routerLinkActive="active-list-item"
            (click)="resetGlobaFiler('supplier_white',true);" *ngIf="authService.isBorrower()
          && authService.isSIDRoleType()">
            <img src="/assets/icons/exporters-icon.svg" id="supplier_grey">
            <img src="/assets/icons/exporters-icon-bg.svg" id="supplier_white">
            <!-- <i class="ri-ship-fill icon-color"></i> -->
            <a matLine>Invoices </a>
          </mat-list-item>

          <div *ngIf="(authService.isBuyer() || authService.isSIDLenderLogin()) && authService.isSIDRoleType()  ">
            <mat-list-item class="repay" routerLink="/sid/listing/buyerSupplier" routerLinkActive="active-list-item"
              (click)="resetGlobaFiler('supplier_white');" *hasPermission="['VIEW_BORROWER']">
              <img src="/assets/icons/exporters-icon.svg" id="supplier_grey">
              <img src="/assets/icons/exporters-icon-bg.svg" id="supplier_white">
              <!-- <i class="ri-ship-fill icon-color"></i> -->
              <a matLine>Suppliers </a>
            </mat-list-item>
          </div>

          <mat-list-item *ngIf="(authService.isSIDRoleType()) && !authService.isJKFennerAnchor()" class="doc"
            routerLink="/escrow-payment" routerLinkActive="active-list-item" (click)="resetGlobaFiler('white_doc_et');">
            <img src="/assets/icons/Escrow_white.svg" id="white_doc_et">
            <img src="/assets/icons/Escrow_blue.svg" id="grey_doc_et">
            <!-- <i class="ri-bank-line icon-color"></i> -->
            <a matLine>Escrow Payment</a>
          </mat-list-item>

          <!-- <mat-list-item class="doc" routerLink="/escrow-payment" routerLinkActive="active-list-item"
          *hasPermission="['ESCROW_VIEW']" (click)="resetGlobaFiler('white_doc_et');">
          <img src="/assets/icons/Escrow_white.svg" id="white_doc_et">
          <img src="/assets/icons/Escrow_blue.svg" id="grey_doc_et">
          <a matLine>Escrow Payment</a>
        </mat-list-item> -->

          <mat-list-item *ngIf="authService.isSIDRoleType() && !authService.isBorrower()" class="doc"
            routerLink="/ledger-report" routerLinkActive="active-list-item" (click)="resetGlobaFiler('ledger-white');">
            <img src="/assets/icons/exporters-icon.svg" id="ledger-grey">
            <img src="/assets/icons/exporters-icon-bg.svg" id="ledger-white">
            <!-- <i class="ri-bank-line icon-color"></i> -->
            <a matLine>Ledger Report</a>
          </mat-list-item>

          <mat-list-item class="repay" routerLink="/payouts" routerLinkActive="active-list-item"
            *hasPermission="['ESCROW_MGMT']" (click)="resetGlobaFiler('white_doc_pt');">
            <img src="/assets/icons/Payout_blue.svg" id="grey_doc_pt">
            <img src="/assets/icons/Payout_white.svg" id="white_doc_pt">
            <!-- <i class="ri-send-plane-fill icon-color"></i> -->
            <a matLine>Payouts </a>
          </mat-list-item>


          <mat-list-item class="repay" routerLink="/payout-report" routerLinkActive="active-list-item"
            *hasPermission="['ESCROW_MGMT']" (click)="resetGlobaFiler('white_doc_pr');">
            <img src="/assets/icons/Payout_blue.svg" id="grey_doc_pr">
            <img src="/assets/icons/Payout_white.svg" id="white_doc_pr">
            <!-- <i class="ri-send-plane-fill icon-color"></i> -->
            <a matLine>Payouts-Report </a>
          </mat-list-item>

          <mat-list-item class="repay" routerLink="/credlixboard" routerLinkActive="active-list-item"
            *hasPermission="['KANBAN']" (click)="resetGlobaFiler('white_doc_cb',true);">
            <img src="/assets/icons/Payout_blue.svg" id="grey_doc_cb">
            <img src="/assets/icons/Payout_white.svg" id="white_doc_cb">
            <!-- <i class="ri-send-plane-fill icon-color"></i> -->
            <a matLine>Credlix-board </a>
          </mat-list-item>
          <!-- <mat-list-item class="repay" routerLink="/presales" routerLinkActive="active-list-item"
          (click)="resetGlobaFiler('white_doc_cb');" *ngIf="authService.isCredlixUser()">
          <img src="/assets/icons/Payout_blue.svg" id="grey_doc_cb">
          <img src="/assets/icons/Payout_white.svg" id="white_doc_cb">
          <a matLine>PreSales </a>
        </mat-list-item> -->

          <mat-list-item class="repay" routerLink="/los-tracker" routerLinkActive="active-list-item"
            *hasPermission="['KANBAN']">
            <mat-icon class="adjustIcon" id="chart_grey"> list_alt</mat-icon>
            <a matLine class="adjustName">Sales Tracker</a>
          </mat-list-item>

          <mat-list-item class="repay" routerLink="/credit-tracker" routerLinkActive="active-list-item"
            *hasPermission="['KANBAN']">
            <mat-icon class="adjustIcon" id="chart_grey"> list_alt</mat-icon>
            <a matLine class="adjustName">Credit Tracker</a>
          </mat-list-item>

          <mat-list-item class="repay" routerLink="/funnel-dashboard" routerLinkActive="active-list-item"
            *hasPermission="['KANBAN']">
            <mat-icon class="adjustIcon" id="chart_grey">dashboard</mat-icon>
            <a matLine class="adjustName">Funnel</a>
          </mat-list-item>

          <mat-list-item *ngIf="authService.isShowApprovedBuyer()" class="repay"
            routerLink="/approvedBuyers" routerLinkActive="active-list-item">
            <mat-icon class="adjustIcon" id="chart_grey">person_pin</mat-icon>
            <a matLine class="adjustName">Approved Buyers</a>
          </mat-list-item>

        </ng-template>
        <mat-list-item *ngIf="!authService.isJKFennerAnchor()" class="e_pay" routerLinkActive="active-list-item"
          (click)="navigateToUserManagement()" style="position: absolute; bottom: 13px;"
          (click)="resetGlobaFiler('settings_white');">
          <img src="/assets/icons/settings_grey.svg" id="settings_grey">
          <img src="/assets/icons/settings_white.svg" id="settings_white">
          <!-- <i class="ri-settings-line icon-color"></i> -->
          <a matLine>Settings</a>
        </mat-list-item>

      </mat-nav-list>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="dasharea">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>



<mat-sidenav-container id="sidenavContainer" fullscreen *ngIf="loginDetails && pagesService.isSubmitted"
  [ngClass]="{'full': sidenavfull == true}">
  <mat-sidenav-content class="dasharea">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<div *ngIf="isLoading" class="overlay">
  <mat-spinner class="spinner" [diameter]="60"></mat-spinner>
</div>

<router-outlet *ngIf="!loginDetails || !pageNotFoundPage"></router-outlet>