import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { urls } from '../../../url';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Console } from 'console';

@Injectable({
  providedIn: 'root'
})
export class SidService {

  readonly BaseUrl = environment.BASEURL;
  //readonly BaseUrl = environment.BaseUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  statusChange = new BehaviorSubject<string>('ALL');


  getNestedObject(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
  }

  uploadBuyerAcceptFile(obj, param) {
    let url = `${this.BaseUrl}${urls.uploadBuyerApproveDocs}${param}`;
    return this.http.post(url, obj);
  }
  //Sid supplier/Buyer APIs
  buyerSearch(obj) {
    let url = this.BaseUrl + urls.buyerSearch;
    return this.http.post(url, obj);
  }

  getInvoiceCount() {
    let url = this.BaseUrl + urls.getInvoicesCount;
    return this.http.get(url);
  }
  getAllStats() {
    let url;
    if (this.authService.isSIDLenderLogin()) {
      let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
      url = this.BaseUrl + urls.getAllLenderStats + "?masterAccId=" + getLocalStorage['role']['anchorMasterAccountId']
    }
    else {
      url = this.BaseUrl + urls.getAllStats;
    }
    return this.http.get(url);
  }

  supplierSearch(obj) {
    let url = this.BaseUrl + urls.supplierSearch;
    return this.http.post(url, obj);
  }

  getBuyerDetails(docType, docnumber) {
    let url = this.BaseUrl + urls.getCompanyInfo + "?documentNo=" + docnumber + "&docType=" +
      docType;
    return this.http.get(url);
  }

  createBuyer(obj) {
    let url = this.BaseUrl + urls.createBuyer;
    if (localStorage.getItem('credlixUserID')) {
      url = url + "?masterAccId=" + localStorage.getItem('credlixUserID') + "&lenderAccId=" + "62bd55164a049a6fa3c54c11"
    }
    return this.http.post(url, obj);
  }

  createSupplier(obj) {
    let url = this.BaseUrl + urls.createSupplier;
    if (localStorage.getItem('credlixUserID')) {
      url = url + "?masterAccountId=" + localStorage.getItem('credlixUserID');
    }
    return this.http.post(url, obj);
  }

  uploadBulkSupplier(obj) {
    let url = this.BaseUrl + urls.uploadBulkSupplier;
    if (localStorage.getItem('credlixUserID')) {
      url = url + "?masterAccountId=" + localStorage.getItem('credlixUserID');
    }
    return this.http.post(url, obj);
  }

  getSupplierBuyerMapping(id) {
    let url = this.BaseUrl + urls.sbMappingGetBuyers + "?supplierId=" + id;
    return this.http.get(url);
  }

  supplierBuyerMapping(obj) {
    let url = this.BaseUrl + urls.mappingSupplierBuyer;
    return this.http.post(url, obj);
  }

  getSupplierLenders(id) {
    let url = this.BaseUrl + urls.getSupplierLenders + "?supplierId=" + id;
    return this.http.get(url);
  }

  getHeadersWithRecords(obj) {
    let url = this.BaseUrl + urls.getHeadersWithRecord;
    return this.http.post(url, obj);
  }

  getLenderListing() {
    let url = this.BaseUrl + urls.getLenderListing;
    if (localStorage.getItem('credlixUserID')) {
      url = url + "?masterAccountId=" + localStorage.getItem('credlixUserID');
    }
    return this.http.get(url);
  }

  supplierLenderMapping(obj) {
    let url = this.BaseUrl + urls.slMapping;
    return this.http.post(url, obj);
  }

  SupplierLenderBuyerMap(supplierId, pageNo, pageSize) {
    let url = this.BaseUrl + urls.mappingSupplierLenderBuyer + "?supplierId=" + supplierId
      + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
    return this.http.get(url);
  }

  getSLBListing(obj) {
    let url = this.BaseUrl + urls.sblMapping;
    return this.http.post(url, obj);
  }

  sbMappingGetSupplier(id, pageNo, pageSize) {
    let url = this.BaseUrl + urls.sbMapGetSupplier + `?pageNo=${pageNo}&pageSize=${pageSize}`;
    if (id) {
      url = `${url}&supplierId=${id}`;
    }
    return this.http.get(url);
  }

  uploadInvoiceDocs(obj, dataType, invoiceId) {
    let url = `${this.BaseUrl}${urls.uploadInvoiceDocs}?dataType=${dataType}&invoiceId=${invoiceId}`;
    return this.http.post(url, obj);
  }

  createInvoice(obj) {
    let url = this.BaseUrl + urls.createInvoice;
    if (localStorage.getItem('credlixUserID')) {
      url = `${url}?masterAccountId=${localStorage.getItem('credlixUserID')}`;
    }
    return this.http.post(url, obj);
  }

  getInvoiceListing(obj) {
    let url = this.BaseUrl + urls.getInvoiceListing;
    return this.http.post(url, obj);
  }

  getInvoiceStats(id) {
    let url;
    if (id) url = this.BaseUrl + urls.getInvoiceStats + `?busAccSearchKey=${id}`;
    else url = this.BaseUrl + urls.getInvoiceStats;
    return this.http.get(url);
  }
  getLenderAccount(id) {
    let url = this.BaseUrl + urls.getLenderAccount + `?masterAccId=${id}`;
    return this.http.get(url);
  }

  generateDrawdownPdf(invoiceId) {
    let url = this.BaseUrl + urls.generateDrawdown + `?invoiceId=${invoiceId}`;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }

  sendInvoiceApproval(invoiceId) {
    let url = this.BaseUrl + urls.sendInvoiceApproval + `?invoiceId=${invoiceId}`;
    return this.http.get(url);
  }
  buyerAutoSuggestList(obj) {
    let url = this.BaseUrl + urls.buyerAutoSuggest;
    return this.http.post(url, obj);
  }

  approveInvoice(invoiceId) {
    let url = this.BaseUrl + urls.sendInvoiceApproval + `?invoiceId=${invoiceId}`;
    return this.http.get(url);
  }

  getInvoiceDetails(invoiceId) {
    let url = this.BaseUrl + urls.getInvoiceDetails + `/${invoiceId}`;
    return this.http.get(url);
  }

  adminApproval(invoiceId, customCharges, proceedWithOverDue) {
    let url = this.BaseUrl + urls.adminApproval + `?invoiceId=${invoiceId}` + `&proceedWithOverDue=${proceedWithOverDue}`;
    return this.http.post(url, customCharges);
  }

  buyerApproval(invoiceId) {
    let url = this.BaseUrl + urls.buyerApproval + `?invoiceId=${invoiceId}`;
    return this.http.post(url, {});
  }

  rejectInvoice(invoiceId, remarks) {
    let url = this.BaseUrl + urls.rejectInvoiceBySidUser + `?invoiceId=${invoiceId}` + `&remarks=${remarks}`;
    return this.http.post(url, {});
  }

  getInvoiceDocuments(invoiceId) {
    let url = this.BaseUrl + urls.getInvoiceDocuments + `/${invoiceId}`;
    return this.http.get(url);
  }

  deleteInvoiceDoc(documentId) {
    let url = this.BaseUrl + urls.deleteInvoiceDoc + `?documentId=${documentId}`;
    return this.http.put(url, {});
  }

  downloadInvoiceDoc(documentId) {
    let url = this.BaseUrl + urls.downloadInvoiceDoc + `?documentId=${documentId}`;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }

  getMappedBuyers(supplierId) {
    let url = this.BaseUrl + urls.getMappedBuyer;
    if (supplierId && supplierId != 'undefined') {
      url = url + '?supplierId=' + supplierId;
    }
    return this.http.get(url);
  }

  getBasicDetails(id) {
    let url = this.BaseUrl + urls.getBasicDetails + '/' + id;
    return this.http.get(url);
  }

  rejectInvoiceFromBuyer(id) {
    let url = this.BaseUrl + urls.rejectInvoiceFromBuyer + "?invoiceId=" + id;
    return this.http.post(url, {});
  }
  deleteInvoicesFromBuyer(obj) {
    let url = this.BaseUrl + urls.deleteInvoices
    return this.http.post(url, obj);
  }

  sendBuyerApproval(id) {
    let url = this.BaseUrl + urls.sendBuyerApproval + "?invoiceId=" + id;
    return this.http.post(url, {});
  }
  updateStatus(businessAccountId, status) {
    let url = this.BaseUrl + urls.updateStatus + `?businessAccountId=${businessAccountId}&status=${status}`;
    return this.http.put(url, {});
  }

  getStates() {
    let url = "https://credlix-public.s3.ap-south-1.amazonaws.com/DropdownData.json";
    return this.http.get(url);
  }

  sendBulkBuyerApproval(ids) {
    let url = this.BaseUrl + urls.bulkApprovalBuyer;
    return this.http.post(url, ids);
  }

  sendBulkAdminApproval(obj) {
    let url = this.BaseUrl + urls.bulkApprovalAdmin;
    return this.http.post(url, obj);
  }

  sendApprovalEmail(obj) {
    let url = this.BaseUrl + urls.sendBuyerApprovalEmail;
    return this.http.post(url, obj);
  }

  sendMultiBuyerApproval(obj) {
    let url = this.BaseUrl + urls.multiBuyerRequest;
    return this.http.post(url, obj);
  }

  downloadSupplierReport(obj, userType) {
    let url = this.BaseUrl + urls.supplierReportDownload;
    if (userType == 'buyer') {
      url = this.BaseUrl + urls.buyerReportDownload;
    }
    return this.http.post(url, obj, { responseType: 'text' });
  }

  getColorCode(obj) {
    let url = this.BaseUrl + urls.getColorCode
    return this.http.put(url, obj);
  }

  downloadInvoicesReport(obj) {
    let url = this.BaseUrl + urls.downloadInvoicesReport;
    return this.http.post(url, obj, { responseType: 'text' });
  }

  getBuyerEmail(id) {
    let url = this.BaseUrl + urls.getBuyerEmail + '/' + id;
    return this.http.get(url);
  }

  getBuyerDocuments(invId) {
    let url = this.BaseUrl + urls.getBuyerDocs + '/' + invId + "?docType=BUYER_APPROVAL";
    return this.http.get(url);
  }

  getBulkBuyerDocuments(invIds) {
    let url = this.BaseUrl + urls.getBuyerBulkDocs + "?docType=BUYER_APPROVAL";
    return this.http.post(url, invIds);
  }

  markDisbursed(obj) {
    let url = this.BaseUrl + urls.sidInvMarkDisbursed;
    return this.http.post(url, obj);
  }

  markPaid(obj) {
    let url = this.BaseUrl + urls.sidInvMarkPaid;
    return this.http.post(url, obj);
  }

  checkOverDue(id) {
    let url = this.BaseUrl + urls.getOverDue + "?supplierId=" + id;
    return this.http.get(url);
  }
  getLimitDetails(obj) {
    let url = this.BaseUrl + urls.getLimitDetails + "?supplierBusAccId=" + obj.id;
    return this.http.get(url);
  }
  getOverDueDetails(obj) {
    let url = this.BaseUrl + urls.getOverDue + '?supplierId=' + obj.id;
    return this.http.get(url);
  }

  getFileExtension(contentDispositionHeader: string): string {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDispositionHeader);
    if (matches != null && matches[1]) {
      const filename = matches[1].replace(/['"]/g, '');
      const extension = filename.split('.').pop();
      return extension.toLowerCase();
    }
    return '';
  }
  getODInvoices(id) {
    let params = new HttpParams();
    params = params.append("supplierId", id)
    let url = this.BaseUrl + urls.getODInvoices;
    return this.http.get(url, { params });
  }
  calculateRepaymentAmount(obj) {
    let url = this.BaseUrl + urls.calculateRepAmount;
    return this.http.post(url, obj);
  }
  submitOdAdjustment(obj) {
    let url = this.BaseUrl + urls.sendOdAdjustment;
    return this.http.post(url, obj);
  }
  getMarkDisbursment(obj) {
    let url = this.BaseUrl + urls.getMarkDisbursedFile;
    return this.http.post(url, obj, { responseType: 'blob' as 'json', observe: 'response' });
  }
  getMDTemplate() {
    let url = this.BaseUrl + urls.makDisbursedTemplate;
    return this.http.get(url,{ responseType: 'blob' as 'json', observe: 'response'});
  }

  getDirectorsOfSupplier(id: string) {
    let url = this.BaseUrl + urls.getDirectorsOfSupplier + "?supplierId=" + id;
    return this.http.get(url);
  }

  saveDirectorsOfSupplier(obj) {
    let url = this.BaseUrl + urls.saveDirectorOfSupplier;
    return this.http.post(url, obj);
  }

  getLenders() {
    let url = this.BaseUrl + urls.getLenders;
    return this.http.get(url);
  }

  generateCibilConsumerReport(obj) {
    let url = this.BaseUrl + urls.generateCibilConsumerReport;
    return this.http.post(url, obj, { observe: 'response', responseType: 'blob' as 'json' });
  }
  generatePaymentShieldTemplate() {
    let url = this.BaseUrl + urls.paymentShieldTemplate;
    return this.http.get(url,{ responseType: 'blob' as 'json', observe: 'response'});
  }
  uploadShieldFinanceFile(obj){
    let url = this.BaseUrl + urls.uploadShieldFinanceFile;
    return this.http.post(url, obj);
  }
}
